import React from "react";

interface Props {
  slug?: string;
  width?: number;
  height?: number;
  className?: string;
  fileType?: string;
}

const TypeIllustration: React.FC<Props> = ({
  slug,
  width,
  height,
  className,
  fileType = "svg",
}) => {
  if (!slug) return null;

  const iconUrl = `/icons/${slug}.${fileType}`;

  return (
    <img className={className} src={iconUrl} width={width} height={height} />
  );
};

export default TypeIllustration;
